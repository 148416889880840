<template>
  <div class="main-body">
    <div class="table-search">
      <a-form
        :model="formState"
        layout="inline"
        autocomplete="off"
        @submit="handleRefresh"
      >
        <a-form-item label="订单编号">
          <a-input
            v-model:value="formState.order_sn"
            placeholder="请输入订单编号"
          />
        </a-form-item>

        <a-form-item label="订单类型">
          <a-select v-model:value="formState.order_type" class="medium-input">
            <a-select-option value="0">全部</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="付款方式">
          <a-select v-model:value="formState.order_type" class="medium-input">
            <a-select-option value="0">全部</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="订单状态">
          <a-select v-model:value="formState.order_type" class="medium-input">
            <a-select-option value="0">全部</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="配送方式">
          <a-select v-model:value="formState.order_type" class="medium-input">
            <a-select-option value="0">全部</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="订单来源">
          <a-select v-model:value="formState.order_type" class="medium-input">
            <a-select-option value="0">全部</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="下单时间">
          <a-range-picker
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm"
            :placeholder="['开始时间', '结束时间']"
          />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-tabs v-model:activeKey="activeKey" @change="changeTab">
      <a-tab-pane key="0" tab="全部"></a-tab-pane>
      <a-tab-pane
        :tab="item.name"
        v-for="item in statusList"
        :key="item.code"
      ></a-tab-pane>
    </a-tabs>

    <a-table :dataSource="dataSource" :columns="columns">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'order_sn'">
          <p>订单编号：{{ record.order_sn }}</p>
          <p>订单类型：普通订单</p>
          <p>订单来源：微信小程序</p>
          <p>下单时间：2022-03-16 21:34:30</p>
        </template>
        <template v-if="column.dataIndex === 'goods'">
          <div v-for="(item, index) in record.goods" :key="index">
            <a-row type="flex">
              <a-col :flex="2"
                ><a-avatar :src="item.goods_image" shape="square" :size="90"
              /></a-col>
              <a-col :flex="3"
                ><p>商品名称：{{ item.goods_name }}</p>
                <p>商品价格：{{ item.price }}</p>
                <p>商品数量：{{ item.number }}</p></a-col
              >
            </a-row>
          </div>
        </template>
        <template v-if="column.dataIndex === 'user'">
          <p>收货人：{{ record.user.username }}</p>
          <p>收货电话：{{ record.user.phone }}</p>
          <p>收地址：{{ record.user.address }}</p>
          <p>配送方式：快递发货</p>
        </template>
        <template v-if="column.dataIndex === 'cost'">
          <p>运费金额：{{ record.cost.freight_price }}</p>
          <p>商品金额：{{ record.cost.goods_price }}</p>
          <p>优惠金额：{{ record.cost.discounts_price }}</p>
          <p>实际支付：{{ record.cost.pay_price }}</p>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <router-link to="/order/manage/detail">
            <a-button type="link" size="small">订单详情</a-button>
          </router-link>
          <a-dropdown>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1">添加备注</a-menu-item>
                <a-menu-item key="2">小票打印</a-menu-item>
                <a-menu-item key="3" danger>删除订单</a-menu-item>
              </a-menu>
            </template>
            <a-button type="link" size="small">
              更多操作
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, reactive } from "vue";
import { DownOutlined } from "@ant-design/icons-vue";
import * as Api from "@/addons/order/api";
// import { message, Modal } from "ant-design-vue";
// import { useRouter } from "vue-router";

const columns = [
  {
    title: "订单信息",
    dataIndex: "order_sn",
    key: "order_sn",
    width: "15%",
  },
  {
    title: "商品信息",
    dataIndex: "goods",
    key: "goods",
    width: "20%",
  },
  {
    title: "买家/收货人",
    dataIndex: "user",
    key: "user",
    width: "20%",
  },
  {
    title: "订单金额",
    dataIndex: "cost",
    key: "cost",
    width: "15%",
  },
  {
    title: "订单状态",
    dataIndex: "status",
    key: "status",
    width: "10%",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    width: "20%",
  },
];

const dataSource = [
  {
    order_sn: "202003164646464",
    goods: [
      {
        goods_image:
          "http://tp6.crate.com/public/uploads/image/20220314/b549a028d257b56a56db6c1799b65a3e.jpeg",
        goods_name: "白色T恤",
        price: "20.00",
        number: "5",
      },
    ],
    user: {
      username: "张三",
      address: "成都市武侯区下一站都市B314",
      phone: "1885354634",
    },
    cost: {
      freight_price: "12.00",
      goods_price: "200.00",
      discounts_price: "10.00",
      pay_price: "202.00",
    },
    status: "已完成",
  },
  {
    order_sn: "202003164646464",
    goods: [
      {
        goods_image:
          "http://tp6.crate.com/public/uploads/image/20220303/680f44f7f433094301402ffd9345a470.jpg",
        goods_name: "白色T恤",
        price: "20.00",
        number: "5",
      },
      {
        goods_image:
          "http://tp6.crate.com/public/uploads/image/20220303/d27d118bc779fd3215a721b699167836.gif",
        goods_name: "泡泡玛特",
        price: "20.00",
        number: "5",
      },
    ],
    user: {
      username: "张三",
      address: "成都市武侯区下一站都市B314",
      phone: "1885354634",
    },
    cost: {
      freight_price: "12.00",
      goods_price: "200.00",
      discounts_price: "10.00",
      pay_price: "202.00",
    },
    status: "已完成",
  },
  {
    order_sn: "202003164646464",
    goods: [
      {
        goods_image:
          "http://tp6.crate.com/public/uploads/image/20220314/b549a028d257b56a56db6c1799b65a3e.jpeg",
        goods_name: "白色T恤",
        price: "20.00",
        number: "5",
      },
    ],
    user: {
      username: "张三",
      address: "成都市武侯区下一站都市B314",
      phone: "1885354634",
    },
    cost: {
      freight_price: "12.00",
      goods_price: "200.00",
      discounts_price: "10.00",
      pay_price: "202.00",
    },
    status: "已完成",
  },
];

export default defineComponent({
  components: {
    DownOutlined,
  },
  setup() {
    const formState = reactive({
      order_sn: "",
      order_type: "0",
    });

    const activeKey = ref("0");

    const changeTab = () => {
      handleRefresh();
    };

    //获取客户端列表
    const statusList = ref();

    const getOrderStatusList = () => {
      Api.order_all_status().then((res) => {
        statusList.value = res.data;
      });
    };

    //刷新订单列表
    const handleRefresh = () => {};

    // 初始化
    onMounted(() => {
      getOrderStatusList();
      handleRefresh();
    });

    return {
      columns,
      dataSource,
      handleRefresh,
      activeKey,
      formState,
      changeTab,
      statusList,
    };
  },
});
</script>

<style scoped lang="less">
.advs-item {
  margin-bottom: 20px;
}
.advs-image-cover {
  object-fit: cover;
  height: 150px;
  width: 250px;
}
</style>